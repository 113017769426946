//
// Main
//


// Desktop mode
@include media-breakpoint-up(lg) {
	.app-main {	
		:not([data-kt-app-footer-fixed="true"]) & {        
			.app-content {			
				padding-bottom: 0 !important;					
			}
		} 	
		
		[data-kt-app-footer-fixed="true"] & {        
			.app-content {			
				padding-bottom: $app-content-padding-y !important;					
			}
		} 				
	}
}
html {
	height: 100%;
  }
  body {
	min-height: 100%;
	display: flex;
	flex-direction: column;
  }
  .app-wrapper {
	height: 100vh;
  }
  table th, table td{
    padding-left: 10px !important;
    max-width: 150px;
  }
  .app-sidebar{
	z-index: 1021 !important;
  }
  .top-35{
	top:35px
  }
  .left-15{
	left:15px
  }
  .table-loader{
	border-radius: 0.475rem;
    box-shadow: var(--bs-dropdown-box-shadow);
    background-color: var(--bs-body-bg);
    color: var(--bs-gray-700);
    font-weight: 500;
	margin: 10px auto;
    width: 145px;
    padding: 1rem 2rem !important;
    transform: translateX(-50%) translateY(-50%);
  }
  .submit-btn{
    border: 0;
    background: transparent;
    color: #fff;
  }
  .category .badge{
    display: block;
    white-space:normal
  }