//
// Dark Sidebar(used by Dark Sidebar layout only)
//

[data-kt-app-layout="dark-sidebar"] {
    .app-sidebar {
        background-color: $app-sidebar-dark-bg-color;
        border-right: 0;

        .scroll-y,
        .hover-scroll-overlay-y {        
            @include scrollbar-color(transparent, $app-sidebar-dark-scrollbar-color-hover);
        }

        .app-sidebar-logo {
            border-bottom: 1px dashed $app-sidebar-dark-separator-color;
        }

        .btn-custom {
            @include button-custom-variant(
                $color:#B5B5C3, 
                $icon-color: #B5B5C3, 
                $border-color: null, 
                $bg-color: rgba(63, 66, 84, 0.35), 
                $color-active: #B5B5C3, 
                $icon-color-active: null, 
                $border-color-active: null, 
                $bg-color-active: rgba(63, 66, 84, 0.35)
            );
        }

        .menu {
            .menu-item {                    
                .menu-heading {
                    color: $app-sidebar-dark-menu-heading-color !important;
                }

                @include menu-link-default-state( 
                    $title-color: $gray-700-dark, 
                    $icon-color:  $gray-400-dark, 
                    $bullet-color:$gray-400-dark,  
                    $arrow-color: $gray-400-dark,  
                    $bg-color: null,
                    $all-links: true 
                );
                
                @include menu-link-here-state( 
                    $title-color: $gray-900-dark,
                    $icon-color: $gray-900-dark, 
                    $bullet-color: $gray-900-dark,
                    $arrow-color: $gray-900-dark, 
                    $bg-color: null,
                    $all-links: true
                );
                
                @include menu-link-show-state( 
                    $title-color: $gray-900-dark,
                    $icon-color: $gray-900-dark, 
                    $bullet-color: $gray-900-dark,
                    $arrow-color: $gray-900-dark,
                    $bg-color: null,
                    $all-links: true
                );
                
                @include menu-link-hover-state( 
                    $title-color: $gray-900-dark,
                    $icon-color: $gray-900-dark, 
                    $bullet-color: $gray-900-dark,
                    $arrow-color: $gray-900-dark,
                    $bg-color: null,
                    $all-links: true
                );
                
                @include menu-link-active-state( 
                    $title-color: $gray-900-dark,
                    $icon-color: $gray-900-dark, 
                    $bullet-color: $gray-900-dark,
                    $arrow-color: $gray-900-dark,
                    $bg-color: $app-sidebar-dark-menu-link-bg-color-active,
                    $all-links: true
                );
            }
        }
    } 
}

// Desktop mode
@include media-breakpoint-up(lg) {
    [data-kt-app-layout="dark-sidebar"] {
        .app-sidebar {
            .menu-sub-lg-dropdown {
                background-color: $app-sidebar-dark-bg-color;
            }
        }
    }
}

// Dark mode
@include color-mode(dark) {
    [data-kt-app-layout="dark-sidebar"] {
        .app-sidebar {
            border-right: 1px solid $app-sidebar-dark-separator-color;
        }
    }
}